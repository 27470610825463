var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createSlice } from "@reduxjs/toolkit";
import { IStatus } from "../../../@types/status";
import { createCallsToday, getCallByDate, getMyCalls, updateCall, getCallById, getAllCall } from "./actions";
var initialState = {
    calls: { docs: [], meta: {} },
    call: {},
    status: IStatus.IDLE
};
var slice = createSlice({
    name: "calls",
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        builder
            .addCase(getMyCalls.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getMyCalls.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            state.calls = payload;
        })
            .addCase(getMyCalls.rejected, function (state) {
            state.status = IStatus.FAILED;
        })
            .addCase(createCallsToday.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(createCallsToday.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            state.calls.docs = __spreadArray(__spreadArray([], state.calls.docs, true), [payload.data], false);
            state.call = __assign({}, payload.data);
        })
            .addCase(createCallsToday.rejected, function (state) {
            state.status = IStatus.FAILED;
        })
            .addCase(getCallByDate.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getCallByDate.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            state.call = payload;
        })
            .addCase(getCallByDate.rejected, function (state) {
            state.status = IStatus.FAILED;
        })
            .addCase(updateCall.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(updateCall.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            state.calls.docs = state.calls.docs.map(function (call) { return call._id !== payload.data._id ? call : payload.data; });
            state.call = __assign({}, payload.data);
        })
            .addCase(updateCall.rejected, function (state) {
            state.status = IStatus.FAILED;
        })
            .addCase(getCallById.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getCallById.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            state.call = __assign({}, payload.data);
        })
            .addCase(getCallById.rejected, function (state) {
            state.status = IStatus.FAILED;
        })
            .addCase(getAllCall.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getAllCall.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            state.calls = payload;
        })
            .addCase(getAllCall.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
    }
});
export var actions = slice.actions;
export default slice.reducer;
